import type { FachbetriebeResponse } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useFachbetriebService = () => {
    const fetch = useFetch({ baseURL: '/api/fachbetriebe' })

    const getAllFachbetriebe = (fetchParams: FetchParams = {}) => {
        return fetch.get<FachbetriebeResponse>({ fetchParams })
    }

    return {
        getAllFachbetriebe
    }
}
