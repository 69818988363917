<script lang="ts" setup>
    import { ref, type Ref } from 'vue'
    import { useFachprueferService } from '@/services'
    import { type Fachpruefer } from '@/js/model'
    import { useLandesstellenStore } from '@/stores'
    import {
        type FetchParams,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import {
        AdresseCell,
        BooleanCell,
        GenderCell,
        KontaktCell,
        LandesstelleFilter,
        type LandesstelleFilterEvent
    } from '@/components'

    const itemsPerPage = ref<number>(25)
    const headers = ref<TableHeaders>([
        { title: 'ID in Access', align: 'start', key: 'fachprueferAccessId' },
        { title: 'Registrierungsnummer', align: 'start', key: 'registrierungsnummer' },
        { title: 'Titel', align: 'start', key: 'titel' },
        { title: 'Vorname', align: 'start', key: 'vorname' },
        { title: 'Nachname', align: 'start', key: 'nachname' },
        { title: 'Aktiv', align: 'start', key: 'isAktiv', sortable: false },
        { title: 'Sachver­ständiger', align: 'start', key: 'isSachverstaendiger', sortable: false },
        { title: 'Bemerkungen', align: 'start', key: 'bemerkungen' },
        { title: 'Geschlecht', align: 'start', key: 'gender', sortable: false },
        { title: 'Firma', align: 'start', key: 'firma', sortable: false },
        { title: 'Landesstellen', align: 'start', key: 'landesstelleIds', sortable: false },
        { title: 'Kontakt Privat', align: 'start', key: 'kontaktPrivat', sortable: false },
        {
            title: 'Kontakt Geschäftlich',
            align: 'start',
            key: 'kontaktGeschaeftlich',
            sortable: false
        },
        { title: 'Adresse Privat', align: 'start', key: 'adressePrivat', sortable: false },
        {
            title: 'Adresse Geschäftlich',
            align: 'start',
            key: 'adresseGeschaeftlich',
            sortable: false
        }
    ])

    const serverItems: Ref<Fachpruefer[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const landesstelleFilter = ref<LandesstelleFilterEvent>({ all: true, selection: [] })

    const fachprueferService = useFachprueferService()
    const landesstellenStore = useLandesstellenStore()

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            ...getLandesstellenFilter()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }
        try {
            const result = await fachprueferService.getAllFachpruefende(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            console.error(e)
            loading.value = false
        }
    }

    const getLandesstellenFilter = () => {
        if (landesstelleFilter.value.all) {
            return { landesstelleIds: null }
        } else {
            return { landesstelleIds: landesstelleFilter.value.selection }
        }
    }
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3">
            <LandesstelleFilter
                @selectionChanged="
                    async (newFilter) => {
                        landesstelleFilter = newFilter
                        await loadItems({ ...tableOptions })
                    }
                "
            />
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        @update:options="loadItems"
    >
        <template v-slot:item.isAktiv="{ item }">
            <BooleanCell :is-enabled="item.isAktiv" />
        </template>
        <template v-slot:item.isSachverstaendiger="{ item }">
            <BooleanCell :is-enabled="item.isSachverstaendiger" />
        </template>
        <template v-slot:item.bemerkungen="{ item }">
            <span class="preformatted-text">{{ item.bemerkungen }}</span>
        </template>
        <template v-slot:item.kontaktPrivat="{ item }">
            <KontaktCell :kontakt="item.kontaktPrivat" />
        </template>
        <template v-slot:item.kontaktGeschaeftlich="{ item }">
            <KontaktCell :kontakt="item.kontaktGeschaeftlich" />
        </template>
        <template v-slot:item.adressePrivat="{ item }">
            <AdresseCell :adresse="item.adressePrivat" />
        </template>
        <template v-slot:item.adresseGeschaeftlich="{ item }">
            <AdresseCell :adresse="item.adresseGeschaeftlich" />
        </template>
        <template v-slot:item.landesstelleIds="{ item }">
            {{ landesstellenStore.getNamesByIds(item.landesstelleIds ?? []) }}
        </template>
        <template v-slot:item.gender="{ item }">
            <GenderCell :gender="item.gender" />
        </template>
        <template v-slot:item.firma="{ item }">
            <template v-if="'' === item.firma?.name"> - </template>
            <template v-else>
                {{ item.firma?.name }}
                <template v-if="item.firma?.rechtsform?.rechtsform">
                    ({{ item.firma?.rechtsform.rechtsform }})
                </template>
            </template>
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
