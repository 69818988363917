<script lang="ts" setup>
    import { ref, watch, type Ref, onBeforeMount } from 'vue'
    import { useFachbetriebService } from '@/services'
    import type { Fachbetrieb } from '@/js/model'
    import { useFachprueferStore, useLandesstellenStore } from '@/stores'
    import {
        itemsPerPageOptions,
        itemsPerPageText,
        formatDateTime,
        formatDate,
        type TableHeaders,
        type SortItem,
        noItemsText,
        loadingText,
        type FetchParams,
        unionBy
    } from '@/utils'
    import {
        ActiveCell,
        AdresseCell,
        KontaktCell,
        LandesstelleFilter,
        type LandesstelleFilterEvent
    } from '@/components'

    const itemsPerPage = ref<number>(25)
    const headers = ref<TableHeaders>([
        { title: 'Mitgliedsnummer', align: 'start', key: 'mitgliedsnummer' },
        { title: 'SBE-Kundennummer', align: 'start', key: 'sbeKundennummer' },
        { title: 'Landesstelle', align: 'start', key: 'landesstelleId' },
        { title: 'Fachprüfer', align: 'start', key: 'fachprueferId' },
        { title: 'laufende Nummer', align: 'start', key: 'laufendeNummer' },
        { title: 'ID in Access', align: 'start', key: 'fachbetriebAccessId' },
        { title: 'Mitgliedsstatus', align: 'start', key: 'isMitglied' },
        {
            title: 'Letzte Regelüberwachung am',
            align: 'start',
            key: 'letzteRegelueberwachungAt',
            value: (item) => `${formatDate(item.letzteRegelueberwachungAt)}`
        },
        { title: 'Firma', align: 'start', key: 'firma.name' },
        { title: 'Rechtsform', align: 'start', key: 'firma.rechtsform.rechtsform' },
        { title: 'Mitarbeiter', align: 'start', key: 'anzahlMitarbeiter' },
        { title: 'Bemerkungen ÜWG', align: 'start', key: 'bemerkungenHauptstelle' },
        { title: 'Bemerkungen LS', align: 'start', key: 'bemerkungenLandesstelle' },
        { title: 'Kontakt', align: 'start', key: 'kontakt', sortable: false },
        { title: 'Adresse', align: 'start', key: 'adresse', sortable: false },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDateTime(item.updatedAt)}`
        }
    ])

    const serverItems: Ref<Fachbetrieb[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const mitgliedsnummer = ref<string | null>(null)
    const isMitglied = ref<boolean | null>(null)
    const search = ref<string>('')
    const landesstelleFilter = ref<LandesstelleFilterEvent>({ all: true, selection: [] })
    const fachprueferFilter = ref<string[]>([])
    const fachprueferFilterOptions = ref<{ title: string; value: string }[]>([])
    const selectAllFachpruefer = ref(true)

    const fachbetriebService = useFachbetriebService()
    const landesstellenStore = useLandesstellenStore()
    const fachprueferStore = useFachprueferStore()

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            mitgliedsnummer: mitgliedsnummer.value,
            isMitglied: isMitglied.value,
            ...getFachprueferFilter(fachprueferFilter.value),
            ...getLandesstellenFilter()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        try {
            const result = await fachbetriebService.getAllFachbetriebe(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            console.error(e)
            loading.value = false
        }
    }

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
    })

    watch([mitgliedsnummer, isMitglied], () => {
        search.value = String(Date.now())
    })

    const onFilterFachpruefer = async (newFilter: string[]) => {
        if (newFilter.length > 0) {
            selectAllFachpruefer.value = false
        }
        await loadItems({ ...tableOptions.value })
    }

    fachprueferStore.$subscribe((_, state) => {
        const newOptions = state.fachpruefende.map((item) => ({
            title: item.fullName,
            value: item.fachprueferId
        }))
        fachprueferFilterOptions.value = unionBy(
            fachprueferFilterOptions.value,
            newOptions,
            'value'
        )
    })

    const getFachprueferFilter = (newFilter: string[]) => {
        if (selectAllFachpruefer.value) {
            return { fachprueferId: null }
        } else {
            return { fachprueferId: newFilter }
        }
    }
    const getLandesstellenFilter = () => {
        if (landesstelleFilter.value.all) {
            return { landesstelleId: null }
        } else {
            return { landesstelleId: landesstelleFilter.value.selection }
        }
    }

    const toggleSelectAllFachpruefer = async () => {
        if (selectAllFachpruefer.value) {
            selectAllFachpruefer.value = false
            await onFilterFachpruefer(fachprueferFilter.value)
        } else {
            selectAllFachpruefer.value = true
            fachprueferFilter.value = []
            await onFilterFachpruefer([])
        }
    }
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3">
            <v-text-field
                v-model="mitgliedsnummer"
                density="compact"
                placeholder="Nach Mitgliedsnummer suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <LandesstelleFilter
                @selectionChanged="
                    async (newFilter) => {
                        landesstelleFilter = newFilter
                        await loadItems({ ...tableOptions })
                    }
                "
            />
        </v-col>
        <v-col cols="12" md="3">
            <v-select
                density="compact"
                v-model="fachprueferFilter"
                :items="fachprueferFilterOptions"
                label="Nach Fachprüfer filtern..."
                multiple
                hide-details
                @update:modelValue="onFilterFachpruefer"
            >
                <template v-slot:prepend-item>
                    <v-list-item title="Alle auswählen" @click="toggleSelectAllFachpruefer">
                        <template v-slot:prepend>
                            <v-checkbox-btn :model-value="selectAllFachpruefer"></v-checkbox-btn>
                        </template>
                    </v-list-item>

                    <v-divider class="mt-2"></v-divider>
                </template>
            </v-select>
        </v-col>
        <v-col cols="12" md="3">
            <v-btn-toggle v-model="isMitglied" color="primary" group density="compact">
                <v-btn value="1">Mitglied</v-btn>
                <v-btn value="0">Kein Mitglied</v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        @update:options="loadItems"
    >
        <template v-slot:item.isMitglied="{ item }">
            <ActiveCell :is-enabled="item.isMitglied ?? false" />
            <div class="mt-1 text-caption">
                <span v-if="item.aufnahmeAt">
                    Aufnahme&nbsp;am&nbsp;{{ formatDate(item.aufnahmeAt) }}
                </span>
                <span v-if="item.austritt">
                    Austritt: {{ item.austritt.austrittsgrund }}
                    {{
                        item.austritt.austrittAt ? `am ${formatDate(item.austritt.austrittAt)}` : ''
                    }}
                </span>
                <span v-if="item.wiedereintrittAt">
                    Wiedereintritt: {{ formatDate(item.wiedereintrittAt) }}
                </span>
            </div>
        </template>
        <template v-slot:item.bemerkungenHauptstelle="{ item }">
            <span class="preformatted-text">{{ item.bemerkungenHauptstelle }}</span>
        </template>
        <template v-slot:item.bemerkungenLandesstelle="{ item }">
            <span class="preformatted-text">{{ item.bemerkungenLandesstelle }}</span>
        </template>
        <template v-slot:item.kontakt="{ item }">
            <KontaktCell :kontakt="item.kontakt" />
        </template>
        <template v-slot:item.adresse="{ item }">
            <AdresseCell :adresse="item.adresse" />
        </template>
        <template v-slot:item.landesstelleId="{ item }">
            {{ landesstellenStore.getNameById(item.landesstelleId) }}
        </template>
        <template v-slot:item.fachprueferId="{ item }">
            {{ fachprueferStore.getFullNameById(item.fachprueferId) }}
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
