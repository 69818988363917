<script setup lang="ts">
    import { useAlertStore } from '@/stores'
    import { useSecurityService } from '@/services'
    import { ref } from 'vue'
    import { type FieldContext, useField, useForm } from 'vee-validate'
    import { FetchError } from 'ofetch'
    import { mapViolationsToFields } from '@/utils'

    type PasswordResetForm = {
        username: string
    }

    const alertStore = useAlertStore()
    const securityService = useSecurityService()

    const isRequestSend = ref(false)
    const { handleSubmit } = useForm<PasswordResetForm>()

    const fields: {
        [Property in keyof PasswordResetForm]: FieldContext<PasswordResetForm[Property]>
    } = {
        username: useField('username', (value) => !!value || 'E-Mailadresse muss angegeben werden.')
    }

    const submit = handleSubmit(async (values) => {
        isRequestSend.value = true

        try {
            await securityService.requestPasswordReset({
                email: '', // always empty because it is used as honeypot when user is not logged in so that we do not need a captcha
                username: values.username
            })
            alertStore.success(
                'Wir haben Ihnen eine E-Mail gesendet. Bitte überprüfen Sie auch Ihren Spam-Ordner. Die nächsten Schritte finden Sie in der E-Mail.'
            )
        } catch (e: unknown) {
            if (e instanceof FetchError && e.statusCode === 422) {
                mapViolationsToFields(fields, e.data)
            }
            isRequestSend.value = false
        }
    })
</script>

<template>
    <v-form @submit.prevent="submit">
        <v-card class="mx-auto" elevation="16" max-width="600">
            <v-card-item>
                <v-card-title>Passwort vergessen oder noch kein Passwort?</v-card-title>
            </v-card-item>

            <v-card-text>
                <ul class="mx-4">
                    <li>
                        Um Ihr Passwort zu ändern, tragen Sie bitte Ihre E-Mail-Adresse ein und
                        senden das Formular ab.
                    </li>
                    <li>Sie bekommen dann eine E-Mail mit einem Link zugeschickt.</li>
                    <li>Mit Hilfe dieses Links können Sie dann ein neues Passwort setzen.</li>
                </ul>

                <v-text-field
                    class="mt-3"
                    v-model="fields.username.value.value"
                    label="E-Mailadresse"
                    name="username"
                    :error-messages="fields.username.errorMessage.value"
                    autocomplete="email"
                />
            </v-card-text>

            <v-card-actions class="d-flex ga-3 flex-column">
                <v-btn color="#00527e" type="submit" variant="flat" block :disabled="isRequestSend"
                    >Passwortänderung beantragen</v-btn
                >
                <v-btn size="x-small" color="#00527e" variant="plain" block :to="{ name: 'login' }"
                    >Zurück zur Anmeldung</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<style scoped></style>
