import type { BetriebsbeauftragteResponse } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useBetriebsbeauftragterService = () => {
    const fetch = useFetch({ baseURL: '/api/betriebsbeauftragte' })

    const getAllBetriebsbeauftragte = (fetchParams: FetchParams = {}) => {
        return fetch.get<BetriebsbeauftragteResponse>({ fetchParams })
    }

    return {
        getAllBetriebsbeauftragte
    }
}
