<script lang="ts" setup>
    import { ref, watch, type Ref, onBeforeMount } from 'vue'
    import { useFachbetriebService } from '@/services'
    import type { Fachbetrieb } from '@/js/model'
    import { useFachprueferStore, useLandesstellenStore } from '@/stores'
    import {
        itemsPerPageOptions,
        itemsPerPageText,
        formatDateTime,
        formatDate,
        type TableHeaders,
        type SortItem,
        noItemsText,
        loadingText,
        type FetchParams
    } from '@/utils'
    import { EmailCell, TelefonnummerCell } from '@/components'

    const itemsPerPage = ref<number>(25)
    const headers = ref<TableHeaders>([
        { title: 'Mitgliedsnummer', align: 'start', key: 'mitgliedsnummer' },
        { title: 'Firma', align: 'start', key: 'firma.name' },
        { title: 'Landesstelle', align: 'start', key: 'landesstelleId' },
        { title: 'Straße', align: 'start', key: 'adresse.strasse' },
        { title: 'PLZ', align: 'start', key: 'adresse.postleitzahl' },
        { title: 'Ort', align: 'start', key: 'adresse.ort' },
        { title: 'Telefon', align: 'start', key: 'kontakt.telefon', sortable: false },
        { title: 'Mobil', align: 'start', key: 'kontakt.mobil', sortable: false },
        { title: 'E-Mail', align: 'start', key: 'kontakt.email', sortable: false },
        {
            title: 'Mangel letzte Prüfung',
            align: 'start',
            key: 'mangelDerPruefung.mangelKey',
            value: (item) => item.mangelDerPruefung.mangel
        },
        { title: 'Bemerkungen', align: 'start', key: 'bemerkung', sortable: false },
        {
            title: '(wiedervorlageAt / Ablauf der Urkunde?)',
            align: 'start',
            key: 'wiedervorlageAt',
            value: (item) => `${formatDate(item.wiedervorlageAt)}`
        },
        {
            title: 'Prüfdatum (DatumPruefungVorOrt)',
            align: 'start',
            key: 'pruefungVorOrtAt',
            value: (item) => `${formatDate(item.pruefungVorOrtAt)}`
        },
        {
            title: 'Letzte Regel-über-wachung am (wann Bogen bei ÜWG eingegangen)',
            align: 'start',
            key: 'letzteRegelueberwachungAt',
            value: (item) => `${formatDate(item.letzteRegelueberwachungAt)}`
        },
        { title: 'Versendet', align: 'start', key: 'versendetAt', sortable: false },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDateTime(item.updatedAt)}`
        }
    ])

    const serverItems: Ref<Fachbetrieb[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const mitgliedsnummer = ref<string | null>(null)
    const search = ref<string>('')

    const fachbetriebService = useFachbetriebService()
    const landesstellenStore = useLandesstellenStore()
    const fachprueferStore = useFachprueferStore()

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            mitgliedsnummer: mitgliedsnummer.value
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        try {
            const result = await fachbetriebService.getAllFachbetriebe(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            console.error(e)
            loading.value = false
        }
    }

    watch([mitgliedsnummer], () => {
        search.value = String(Date.now())
    })

    onBeforeMount(async () => {
        await Promise.all([
            fachprueferStore.fetchFachpruefende(),
            landesstellenStore.fetchLandesstellen()
        ])
    })
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3">
            <v-text-field
                v-model="mitgliedsnummer"
                density="compact"
                placeholder="Nach Mitgliedsnummer suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        @update:options="loadItems"
    >
        <template v-slot:item.bemerkungenHauptstelle="{ item }">
            <span class="preformatted-text">{{ item.bemerkungenHauptstelle }}</span>
        </template>
        <template v-slot:item.bemerkungenLandesstelle="{ item }">
            <span class="preformatted-text">{{ item.bemerkungenLandesstelle }}</span>
        </template>
        <template v-slot:item.kontakt.telefon="{ item }">
            <template v-for="(telefonnummer, key) in item?.kontakt?.telefon" :key="key">
                <TelefonnummerCell :telefonnummer="telefonnummer" />
            </template>
        </template>
        <template v-slot:item.kontakt.mobil="{ item }">
            <template v-for="(mobil, key) in item?.kontakt?.mobil" :key="key">
                <TelefonnummerCell :telefonnummer="mobil" />
            </template>
        </template>
        <template v-slot:item.kontakt.email="{ item }">
            <template v-for="(email, key) in item?.kontakt?.email" :key="key">
                <EmailCell :email="email" />
            </template>
        </template>
        <template v-slot:item.landesstelleId="{ item }">
            {{ landesstellenStore.getNameById(item.landesstelleId) }}
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
