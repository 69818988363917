import type { Login } from '@/js/model'
import { useFetch } from '@/utils'

export const useLoginService = () => {
    const fetch = useFetch({ baseURL: '/api/getToken' })

    const login = (body: { username: string; password: string }) => {
        return fetch.post<Login>({ body })
    }

    return {
        login
    }
}
