<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { useLogService } from '@/services'
    import type { LogRequest } from '@/js/model'

    const logFileLines = ref<string[] | undefined>([])
    const filenameFilterOptions = ref<string[]>([
        'csv_import.log',
        'dev.log',
        'test.log',
        'prod.log'
    ])
    const rowsFilterOptions = ref<number[]>([50, 100, 250, 500, 1000])
    const filename = ref<string>('csv_import.log')
    const rows = ref<number>(50)

    const loading = ref<boolean>(true)

    const logService = useLogService()

    onMounted(async () => {
        await reloadLogFile()
    })

    async function reloadLogFile() {
        const data: LogRequest = {
            filename: filename.value,
            rows: rows.value
        }
        loading.value = true
        try {
            const result = await logService.getLog(data)
            loading.value = false
            logFileLines.value = result.lines
        } catch (e: unknown) {
            console.error(e)
            loading.value = false
        }
    }
</script>

<template>
    <v-row class="">
        <v-col cols="9" md="9">
            <v-select
                v-model="filename"
                :items="filenameFilterOptions"
                label="Dateinamen wählen"
                @update:model-value="reloadLogFile"
            />
        </v-col>
        <v-col cols="3" md="3">
            <v-select
                v-model="rows"
                :items="rowsFilterOptions"
                label="Anzahl Zeilen"
                @update:model-value="reloadLogFile"
            />
        </v-col>
    </v-row>
    <v-row class="pb-4">
        <pre
            v-if="logFileLines?.length"
            class="mx-3 pa-2 bg-grey-darken border text-body-2 text-pre-wrap w-full log-content"
        ><template
            v-for="(line,index) in logFileLines"
            :key="index"
        >{{ line }}<template v-if="'' !== line"><br></template></template>
        </pre>

        <div v-else class="mx-3 pa-2">Keine Log-Daten zum Anzeigen vorhanden</div>
    </v-row>
</template>

<style scoped>
    .log-content {
        min-width: calc(100% - 24px);
        word-break: break-word;
    }
</style>
