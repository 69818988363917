import type {
    ChangePasswordRequest,
    IsConfirmationTokenValidRequest,
    RequestPasswordResetRequest,
    User
} from '@/js/model'
import { useFetch } from '@/utils'

export const useSecurityService = () => {
    const fetch = useFetch({ baseURL: '/api/security' })

    const requestPasswordReset = (body: RequestPasswordResetRequest) => {
        return fetch.post({ url: '/request-password-reset', body })
    }

    const changePassword = (body: ChangePasswordRequest) => {
        return fetch.post<User>({ url: '/change-password', body })
    }

    const validateConfirmationToken = (body: IsConfirmationTokenValidRequest) => {
        return fetch.post({ url: '/is-confirmation-token-valid', body })
    }

    return {
        changePassword,
        requestPasswordReset,
        validateConfirmationToken
    }
}
