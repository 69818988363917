import { useAuthStore } from '@/stores'
import { ofetch, type FetchOptions, type FetchContext } from 'ofetch'
import { type FetchParams, useParamTransformer } from './fetch-params'
import { isEmpty } from './is-empty'

enum HttpMethod {
    'GET' = 'GET',
    'POST' = 'POST',
    'PUT' = 'PUT',
    'DELETE' = 'DELETE'
}

const createUrlAppend = (options: { url?: string; fetchParams?: FetchParams }): string => {
    return `${options.url ?? ''}${isEmpty(options.fetchParams) ? '' : `?${useParamTransformer(options.fetchParams ?? {})}`}`
}

export const useFetch = (options: FetchOptions = {}) => {
    const ofetchInstance = ofetch.create(options)

    return {
        get: <T>(options: { url?: string; fetchParams?: FetchParams }) =>
            ofetchInstance<T>(createUrlAppend(options), {
                method: HttpMethod.GET,
                onRequest: ({ options }: FetchContext) => {
                    options.headers = {
                        ...options.headers,
                        ...contentTypeHeaders(),
                        ...authHeader(),
                        ...switchHeader()
                    }
                }
            }),
        post: <T>(options: {
            url?: string
            fetchParams?: FetchParams
            body?: FetchOptions['body']
        }) =>
            ofetchInstance<T>(createUrlAppend(options), {
                method: HttpMethod.POST,
                body: options.body,
                onRequest: ({ options }: FetchContext) => {
                    options.headers = {
                        ...options.headers,
                        ...contentTypeHeaders(),
                        ...authHeader(),
                        ...switchHeader()
                    }
                }
            }),
        put: <T>(options: {
            url?: string
            fetchParams?: FetchParams
            body?: FetchOptions['body']
        }) =>
            ofetchInstance<T>(createUrlAppend(options), {
                method: HttpMethod.PUT,
                body: options.body,
                onRequest: ({ options }: FetchContext) => {
                    options.headers = {
                        ...options.headers,
                        ...contentTypeHeaders(),
                        ...authHeader(),
                        ...switchHeader()
                    }
                }
            }),
        delete: <T>(options: { url?: string; fetchParams?: FetchParams }) =>
            ofetchInstance<T>(createUrlAppend(options), {
                method: HttpMethod.DELETE,
                onRequest: ({ options }: FetchContext) => {
                    options.headers = {
                        ...options.headers,
                        ...contentTypeHeaders(),
                        ...authHeader(),
                        ...switchHeader()
                    }
                }
            })
    }
}

const contentTypeHeaders = (): Record<string, string> => ({
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json'
})

const authHeader = (): Record<string, string> => {
    const { token } = useAuthStore()
    const isLoggedIn = !!token

    if (isLoggedIn) {
        return { Authorization: `Bearer ${token}` }
    }

    return {}
}

const switchHeader = (): Record<string, string> => {
    const { isUserSwitched, currentUser } = useAuthStore()

    if (isUserSwitched && currentUser) {
        return { '-SWITCH-USER': `${currentUser.email.email}` }
    }

    return {}
}
