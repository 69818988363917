import './css/vera-app.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import App from './App.vue'

const pinia = createPinia()
const veraApp = createApp(App)
const vuetify = createVuetify({
    // Check https://pictogrammers.com/library/mdi/ for possible icons & their names
    icons: {
        defaultSet: 'mdi'
    },
    components,
    directives
})

veraApp.use(router).use(pinia).use(vuetify).mount('#app')
