<script setup lang="ts">
    import { useAlertStore, useAuthStore } from '@/stores'
    import { useSecurityService } from '@/services'
    import { storeToRefs } from 'pinia'
    import { computed, ref } from 'vue'

    const alertStore = useAlertStore()
    const securityService = useSecurityService()
    const authStore = useAuthStore()
    const { currentUser } = storeToRefs(authStore)

    const isRequestSend = ref(false)
    const isDisabled = computed(() => {
        return !username.value || isRequestSend.value
    })

    const username = computed(() => {
        return currentUser.value?.email.email
    })

    const resetPassword = async () => {
        isRequestSend.value = true
        try {
            await securityService.requestPasswordReset({
                email: '', // always empty because it is used as honeypot when user is not logged in so that we do not need a captcha
                username: username.value
            })
            alertStore.success(
                'Wir haben Ihnen eine E-Mail gesendet. Bitte überprüfen Sie auch Ihren Spam-Ordner. Die nächsten Schritte finden Sie in der E-Mail.'
            )
        } catch (e: unknown) {
            alertStore.error('Das hat leider nicht geklappt. Versuchen Sie es später erneut.')
            isRequestSend.value = false
        }
    }
</script>

<template>
    <v-card class="mx-auto" elevation="16" max-width="600">
        <v-card-item>
            <v-card-title>Passwort ändern</v-card-title>
        </v-card-item>

        <v-card-text class="mx-4">
            <ul>
                <li>Um Ihr Passwort zu ändern, drücken Sie bitte folgenden Knopf.</li>
                <li>Sie bekommen dann eine E-Mail mit einem Link zugeschickt.</li>
                <li>Mit Hilfe dieses Links können Sie dann ein neues Passwort setzen.</li>
            </ul>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="#00527e"
                variant="flat"
                block
                :disabled="isDisabled"
                @click="resetPassword"
                >Neues Passwort beantragen</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<style scoped></style>
