<script setup lang="ts">
    import { computed } from 'vue'

    const props = defineProps<{
        gender?: {
            isMale?: boolean
            isFemale?: boolean
            isDivers?: boolean
            isUnknown?: boolean
        }
    }>()

    const labels = {
        isMale: 'Männlich',
        isFemale: 'Weiblich',
        isDivers: 'Divers',
        isUnknown: 'Unbekannt'
    } as const

    const shownGender = computed(() => {
        if (!props.gender) {
            return labels['isUnknown']
        }
        return props.gender.isMale
            ? labels['isMale']
            : props.gender.isFemale
              ? labels['isFemale']
              : props.gender.isDivers
                ? labels['isDivers']
                : labels['isUnknown']
    })
</script>

<template>
    <span>
        {{ shownGender }}
    </span>
</template>

<style scoped></style>
