import router from '@/router'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useLoginService, useUserService } from '@/services'
import type { User } from '@/js/model'
import { determineRoles } from '@/utils'
import type { RollenName } from '@/js/model'

const TOKEN_STORAGE_KEY = 'vera-token' as const
const USER_IRI_STORAGE_KEY = 'vera-user-iri' as const
const ORIGIN_USER_IRI_STORAGE_KEY = 'vera-user-origin-iri' as const

export const useAuthStore = defineStore('auth', () => {
    const loginService = useLoginService()
    const userService = useUserService()
    const token = ref(localStorage.getItem(TOKEN_STORAGE_KEY) || null)
    const currentUserIri = ref(localStorage.getItem(USER_IRI_STORAGE_KEY) || null)
    const originUserIri = ref(localStorage.getItem(ORIGIN_USER_IRI_STORAGE_KEY) || null)
    const currentUser = ref<User | null>(null)
    const isLoading = ref(false)
    const isAuthenticated = computed(() => !!token.value && !!currentUserIri.value)
    const currentUserRoles = computed(() => determineRoles(currentUser.value?.rollen))
    const isUserSwitched = computed(() => !!originUserIri.value)

    const hasRole = (role: RollenName): boolean => currentUserRoles.value.includes(role)

    const login = async (body: { username: string; password: string }) => {
        const result = await loginService.login(body)
        token.value = result.token
        localStorage.setItem(TOKEN_STORAGE_KEY, token.value)
        currentUserIri.value = result['@id']
        localStorage.setItem(USER_IRI_STORAGE_KEY, currentUserIri.value)
        await fetchCurrentUser()
    }

    const logout = () => {
        token.value = null
        localStorage.removeItem(TOKEN_STORAGE_KEY)
        currentUserIri.value = null
        localStorage.removeItem(USER_IRI_STORAGE_KEY)
        localStorage.removeItem(ORIGIN_USER_IRI_STORAGE_KEY)
        isUserSwitched.value ? exitUserSwitch() : router.push({ name: 'login' })
    }

    const fetchCurrentUser = () => {
        return userService.getUserByIri(currentUserIri.value).then((res) => {
            currentUser.value = res
        })
    }

    const switchUser = (newUser: User) => {
        originUserIri.value = currentUserIri.value
        localStorage.setItem(ORIGIN_USER_IRI_STORAGE_KEY, currentUserIri.value as string)

        currentUserIri.value = newUser['@id'] as string
        localStorage.setItem(USER_IRI_STORAGE_KEY, newUser['@id'] as string)

        router.go(0)
    }

    const exitUserSwitch = () => {
        currentUserIri.value = originUserIri.value
        localStorage.setItem(USER_IRI_STORAGE_KEY, originUserIri.value as string)

        originUserIri.value = null
        localStorage.removeItem(ORIGIN_USER_IRI_STORAGE_KEY)

        router.go(0)
    }

    return {
        currentUser,
        currentUserRoles,
        exitUserSwitch,
        fetchCurrentUser,
        hasRole,
        isAuthenticated,
        isLoading,
        isUserSwitched,
        login,
        logout,
        switchUser,
        token
    }
})
