<script lang="ts" setup>
    import { useAlertStore, useAuthStore } from '@/stores'
    import { UserMenu } from '@/components'

    const authStore = useAuthStore()
    const alertStore = useAlertStore()
</script>

<template>
    <v-app>
        <v-container class="main-container">
            <v-app-bar v-if="authStore.token" class="app-bar" color="primary" dark>
                <v-container class="d-flex align-center justify-space-between">
                    <v-app-bar-title>Vera</v-app-bar-title>
                    <div class="d-flex justify-center flex-grow-1">
                        <RouterLink
                            :to="{ name: 'betriebspruefungen' }"
                            class="nav-item nav-link"
                            data-test="betriebspruefungen-nav"
                        >
                            Betriebsprüfungen
                        </RouterLink>
                        <RouterLink
                            :to="{ name: 'fachbetriebe' }"
                            class="nav-item nav-link"
                            data-test="fachbetriebe-nav"
                        >
                            Fachbetriebe
                        </RouterLink>
                        <RouterLink
                            :to="{ name: 'betriebsbeauftragte' }"
                            class="nav-item nav-link"
                            data-test="betriebsbeauftragte-nav"
                        >
                            Betriebsbeauftragte
                        </RouterLink>
                        <RouterLink
                            :to="{ name: 'fachpruefer' }"
                            class="nav-item nav-link"
                            data-test="fachpruefer-nav"
                        >
                            Fachprüfer
                        </RouterLink>
                        <RouterLink
                            :to="{ name: 'users' }"
                            class="nav-item nav-link"
                            data-test="benutzer-nav"
                        >
                            Benutzer
                        </RouterLink>
                    </div>
                    <v-spacer></v-spacer>
                    <v-menu v-if="authStore.hasRole('Uewg')">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" icon="mdi-shield-account-variant" />
                        </template>

                        <v-list>
                            <v-list-item link :to="{ name: 'logView' }">
                                <v-list-item-title>Logs</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div class="ml-1" data-test="nav-user-item">
                        <UserMenu></UserMenu>
                    </div>
                </v-container>
            </v-app-bar>

            <v-main>
                <v-container fluid>
                    <RouterView />
                </v-container>
            </v-main>
        </v-container>
        <v-snackbar
            v-model="alertStore.showAlert"
            multi-line
            timeout="15000"
            transition="false"
            :color="alertStore.alert?.type ?? undefined"
        >
            {{ alertStore.alert?.message }}
            <template v-slot:actions>
                <v-btn variant="text" @click="alertStore.clear()">
                    <v-icon icon="mdi-close"></v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<style scoped>
    .main-container {
        max-width: 2400px;
        margin: 0 auto;
        padding: 16px;
        background-color: #f4f4f9;
        min-height: 100dvh;
    }

    .app-bar {
        max-width: 2400px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .nav-item {
        margin-left: 16px;
        margin-right: 16px;
    }

    .nav-link {
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        transition: color 0.3s;
    }

    .nav-link:hover {
        color: #ffeb3b; /* Yellow color on hover */
    }
</style>
