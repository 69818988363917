import type { User } from '@/js/model'
import type { RollenName } from '@/js/model'

export const determineRoles = (model?: User['rollen']): RollenName[] => {
    if (!model) {
        return []
    }

    return model.rollen.reduce<RollenName[]>((acc, rolle) => {
        return [...acc, rolle.rollenName.rollenName]
    }, [])
}
