import type { Log, LogRequest } from '@/js/model'
import { useFetch } from '@/utils'

export const useLogService = () => {
    const fetch = useFetch({ baseURL: '/api/logs' })

    const getLog = (body: LogRequest) => {
        return fetch.post<Log>({ body })
    }

    return {
        getLog
    }
}
