import { useAuthStore } from '@/stores'
import {
    createRouter,
    createWebHistory,
    type NavigationGuardWithThis,
    type RouteRecordRaw
} from 'vue-router'
import {
    Dashboard,
    ChangePassword,
    Login,
    LogView,
    BetriebspruefungList,
    BetriebsbeauftragterList,
    FachbetriebList,
    FachprueferList,
    PasswordReset,
    RequestPasswordChange,
    UsersList
} from '@/pages'

declare module 'vue-router' {
    interface RouteMeta {
        skipAuth?: boolean
    }
}

const checkAdminGuard: NavigationGuardWithThis<undefined> = () => {
    const { hasRole } = useAuthStore()
    if (hasRole('Uewg')) {
        return true
    }
    return { name: 'dashboard' }
}

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/benutzer',
        name: 'users',
        component: UsersList
    },
    {
        path: '/betriebsbeauftragte',
        name: 'betriebsbeauftragte',
        component: BetriebsbeauftragterList
    },
    {
        path: '/betriebspruefungen',
        name: 'betriebspruefungen',
        component: BetriebspruefungList
    },
    {
        path: '/fachbetriebe',
        name: 'fachbetriebe',
        component: FachbetriebList
    },
    {
        path: '/fachpruefer',
        name: 'fachpruefer',
        component: FachprueferList
    },
    {
        path: '/passwort-aenderung-beantragen',
        name: 'requestPasswordChange',
        component: RequestPasswordChange
    },
    // Public
    {
        path: '/anmelden',
        name: 'login',
        component: Login,
        meta: { skipAuth: true }
    },
    {
        path: '/passwort-zuruecksetzen',
        name: 'passwordReset',
        component: PasswordReset,
        meta: { skipAuth: true }
    },
    {
        path: '/passwort-aendern/:userId/:confirmationToken',
        name: 'changePassword',
        component: ChangePassword,
        props: true,
        meta: { skipAuth: true }
    },
    // Admin
    {
        path: '/verwaltung/logs-einsehen',
        name: 'logView',
        component: LogView,
        beforeEnter: checkAdminGuard
    },
    { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes
})

router.beforeEach(async (to) => {
    const { isAuthenticated, currentUser, logout, fetchCurrentUser } = useAuthStore()

    if (isAuthenticated && !currentUser) {
        try {
            await fetchCurrentUser()
        } catch (e: unknown) {
            console.error(e)
            return logout()
        }
    }

    if (to.meta.skipAuth) {
        return true
    }

    if (!isAuthenticated) {
        return { name: 'login' }
    }
})

export default router
