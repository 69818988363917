import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Landesstelle } from '@/js/model'
import { useLandesstellenService } from '@/services'

export const useLandesstellenStore = defineStore('landesstellen', () => {
    const landesstellen = ref<Landesstelle[]>([])
    const service = useLandesstellenService()

    const getNameById = (id: Landesstelle['landesstelleId']): string => {
        return landesstellen.value.find((item) => item.landesstelleId === id)?.name ?? ''
    }

    const getNamesByIds = (ids: Array<Landesstelle['landesstelleId']>): string => {
        const matchingItems = landesstellen.value
            .filter((item) => ids.includes(item.landesstelleId))
            .filter((item) => !!item.name)
            .map((item) => item.name)
        if (matchingItems.length > 0) {
            return matchingItems.join(', ')
        }
        return ''
    }

    const fetchLandesstellen = async () => {
        return service.getAllLandesstellen().then((result) => {
            landesstellen.value = result['hydra:member']
        })
    }

    return { landesstellen, fetchLandesstellen, getNameById, getNamesByIds }
})
