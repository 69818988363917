import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Fachpruefer } from '@/js/model'
import { useFachprueferService } from '@/services'
import { useLandesstellenStore } from '@/stores'

export const useFachprueferStore = defineStore('fachpruefer', () => {
    const fachpruefende = ref<Fachpruefer[]>([])
    const service = useFachprueferService()
    const landesstellenStore = useLandesstellenStore()

    const getFullNameById = (id?: string | null): string => {
        return fachpruefende.value.find((item) => item.fachprueferId === id)?.fullName ?? ''
    }

    const fetchFachpruefende = async () => {
        return service.getAllFachpruefende().then((result) => {
            fachpruefende.value = result['hydra:member']
        })
    }

    const getLandesstelleByFachprueferId = (id: string) => {
        const fp = fachpruefende.value.find((item) => item.fachprueferId === id)
        if (fp) {
            return landesstellenStore.getNamesByIds(fp.landesstelleIds)
        } else {
            return ''
        }
    }

    return { fachpruefende, fetchFachpruefende, getFullNameById, getLandesstelleByFachprueferId }
})
