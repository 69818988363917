import type { LandesstellenResponse } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useLandesstellenService = () => {
    const fetch = useFetch({ baseURL: '/api/landesstellen' })

    const getAllLandesstellen = (fetchParams: FetchParams = {}) => {
        return fetch.get<LandesstellenResponse>({ fetchParams })
    }

    return {
        getAllLandesstellen
    }
}
