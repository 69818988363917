<script setup lang="ts">
    import { onBeforeMount, ref, defineEmits } from 'vue'
    import { useLandesstellenStore } from '@/stores'
    import { unionBy } from '@/utils'

    export type LandesstelleFilterEvent = {
        all: boolean
        selection: string[]
    }

    const emit = defineEmits<{
        selectionChanged: [event: LandesstelleFilterEvent]
    }>()

    const landesstellenStore = useLandesstellenStore()
    const landesstelleFilter = ref<string[]>([])
    const landesstelleFilterOptions = ref<{ title: string; value: string }[]>([])
    const selectAllLandesstellen = ref(true)

    const toggleSelectAllLandesstellen = () => {
        if (selectAllLandesstellen.value) {
            selectAllLandesstellen.value = false
            onFilterLandesstellen(landesstelleFilter.value)
        } else {
            selectAllLandesstellen.value = true
            landesstelleFilter.value = []
            onFilterLandesstellen([])
        }
    }

    const onFilterLandesstellen = (newFilter: string[]) => {
        if (newFilter.length > 0) {
            selectAllLandesstellen.value = false
        }
        emit('selectionChanged', {
            all: selectAllLandesstellen.value,
            selection: landesstelleFilter.value
        })
    }

    onBeforeMount(async () => {
        await Promise.all([landesstellenStore.fetchLandesstellen()])
    })

    landesstellenStore.$subscribe((_, state) => {
        const newOptions = state.landesstellen.map((item) => ({
            title: item.name,
            value: item.landesstelleId
        }))
        landesstelleFilterOptions.value = unionBy(
            landesstelleFilterOptions.value,
            newOptions,
            'value'
        )
    })
</script>

<template>
    <v-select
        density="compact"
        v-model="landesstelleFilter"
        :items="landesstelleFilterOptions"
        label="Nach Landesstelle filtern..."
        multiple
        hide-details
        @update:modelValue="onFilterLandesstellen"
    >
        <template v-slot:prepend-item>
            <v-list-item title="Alle auswählen" @click="toggleSelectAllLandesstellen">
                <template v-slot:prepend>
                    <v-checkbox-btn :model-value="selectAllLandesstellen"></v-checkbox-btn>
                </template>
            </v-list-item>

            <v-divider class="mt-2"></v-divider>
        </template>
    </v-select>
</template>

<style scoped></style>
