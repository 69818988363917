import type { FachpruefendeResponse } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useFachprueferService = () => {
    const fetch = useFetch({ baseURL: '/api/fachpruefende' })

    const getAllFachpruefende = (fetchParams: FetchParams = {}) => {
        return fetch.get<FachpruefendeResponse>({ fetchParams })
    }

    return {
        getAllFachpruefende
    }
}
